import React from "react";
import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ExpandableCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const CollapsibleCol = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
`;

export const Button = styled.button`
  border: 0;
  border-radius: 10px;
  outline: none;

  &:hover {
    filter: brightness(1.15);
  }

  &:active {
    filter: brightness(1.3);
  }
`;
