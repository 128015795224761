import { configureStore } from "@reduxjs/toolkit";
import windowsReducer from "./features/windows/windows";
import desktopReducer from "./features/desktop/desktop";

export default configureStore({
  reducer: {
    window: windowsReducer,
    desktop: desktopReducer
  },
});
