import React, { useEffect, useRef, useState } from "react";
import "./Browser.scss";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BrowserInputPrefix = styled.div`
  font-size: 14px;
  border: solid #cccccc;
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  margin-bottom: 10px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  color: #777777;
`;

const BrowserInput = styled.input`
  width: 100%;
  flex: 1;
  border: solid #cccccc;
  border-width: 1px 1px 1px 0 !important;
  border-radius: 0 4px 4px 0 !important;
  font-size: 14px;
  margin-bottom: 10px;
  outline: 0 !important;
  padding-left: 0 !important;
`;

const BrowserFrame = styled.iframe`
  flex: 1;
  border: solid 1px #cccccc;
  border-radius: 2px;
`;

const Browser = () => {
  const [url, setUrl] = useState("");
  const iframeRef = useRef();

  const onSubmit = (e) => {
    e.preventDefault();
    iframeRef.current.src = "https://" + url;
  };

  return (
    <Wrapper>
      <form onSubmit={onSubmit} style={{ display: "flex" }}>
        <BrowserInputPrefix>https://</BrowserInputPrefix>
        <BrowserInput onChange={(e) => setUrl(e.target.value)} value={url} />
      </form>
      <BrowserFrame ref={iframeRef} />
    </Wrapper>
  );
};

export default Browser;
