import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Window, { CloseButton } from "../window/Window";
import moment from "moment";
import { CollapsibleCol, ExpandableCol, Row } from "../layout";
import { closeWindow } from "../../../redux/features/windows/windows";
import { useDispatch } from "react-redux";
import { toggleSearch } from "../../../redux/features/desktop/desktop";

const Container = styled.div`
  position: absolute;
  height: 36px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  padding: 6px;
  box-sizing: border-box;
`;

const WindowButton = styled.div`
  margin-right: 6px;
  padding: 4px;
  border: 0;
  border-radius: 10px;
  outline: 0;
  background-color: ${({ isActive }) => {
    return isActive ? "#ffffff" : "rgba(255, 255, 255, 0.3)";
  }};
  text-align: center;
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.3);
  }
`;

const Time = styled.div`
  margin-right: 6px;
  padding: 4px;
  border: 0;
  border-radius: 10px;
  outline: 0;
  background-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
`;

const TaskbarButton = styled.button`
  margin-top: -6px;
  margin-bottom: -6px;
  border: 0;
  background: rgba(255, 255, 255, 0);
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .button-text {
    width: 0;
    overflow: hidden;
    transition: width 200ms ease-in-out;
    white-space: nowrap;
  }

  &:last-child {
    margin-right: -6px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.5);

    .button-text {
      width: 80px;
      overflow: hidden;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  img {
    height: 16px;
    width: 16px;
  }
`;

const isFullscreen = () => {
  // eslint-disable-next-line no-restricted-globals
  return 1 >= outerHeight - innerHeight;
};

const toggleFullScreen = () => {
  const elem = document.documentElement;

  if (isFullscreen()) {
    // browser is fullscreen
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }
};

const Taskbar = ({ windows, activeWindow, onTabClick, launchApp }) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(moment().format("h:mm a"));

  useEffect(() => {
    setInterval(() => {
      setTime(moment().format("h:mm a"));
    }, 1000);
  }, []);
  return (
    <Container>
      <ExpandableCol style={{ overflowX: "auto" }}>
        {Object.keys(windows).map((wKey) => {
          return (
            <WindowButton
              key={"windowbutton-" + wKey}
              isActive={wKey === activeWindow}
            >
              <Row>
                <CollapsibleCol style={{ minWidth: "16px" }} />
                <ExpandableCol
                  style={{ justifyContent: "center" }}
                  onClick={() => onTabClick(wKey)}
                >
                  {windows[wKey].minimized ? "▽ " : ""}
                  {windows[wKey].title}
                </ExpandableCol>
                <CollapsibleCol>
                  <CloseButton onClick={() => dispatch(closeWindow(wKey))} />
                </CollapsibleCol>
              </Row>
            </WindowButton>
          );
        })}
      </ExpandableCol>
      <CollapsibleCol style={{ paddingRight: "6px" }}>
        <Time onClick={() => launchApp("clock")}>{time}</Time>
        <TaskbarButton onClick={() => dispatch(toggleSearch())}>
          <img src={require("../../../assets/search.png").default} />
          <div className="button-text">Search</div>
        </TaskbarButton>
        <TaskbarButton onClick={() => toggleFullScreen()}>
          <img src={require("../../../assets/full-size.png").default} />
          <div className="button-text">Full Screen</div>
        </TaskbarButton>
      </CollapsibleCol>
    </Container>
  );
};

export default Taskbar;
