import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { Resizable, ResizableBox } from "react-resizable";
import "./Window.scss";
import styled from "styled-components";
import { Button, CollapsibleCol, ExpandableCol } from "../layout";

import { useSelector, useDispatch } from "react-redux";
import {
  maximizeWindow,
  minimizeWindow,
  closeWindow,
  resizeWindow,
  fadeWindow,
} from "../../../redux/features/windows/windows";
import getLayout from "./layoutBuilder";
import { getAppContentById } from "../../../apps/apps";

const Container = styled.div`
  position: absolute;
  top: ${({ state }) => (state.maximized ? "unset" : state.t + "px")};
  left: ${({ state }) => (state.maximized ? "unset" : state.l + "px")};
  width: ${({ state }) => (state.maximized ? "unset" : state.w + "px")};
  height: ${({ state }) => (state.maximized ? "unset" : state.h + "px")};
  border-radius: 8px;
  background-color: ${({ isActive }) =>
    isActive ? "rgba(255,255,255,1)" : "rgba(255, 255, 255, 0.3)"};
  z-index: ${({ isActive }) => (isActive ? 10 : 2)};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: ${({ isLoaded, isActive }) =>
    isLoaded ? (isActive ? "1" : "0.7") : 0};
  transition: width 300ms ease-in-out, height 300ms ease-in-out,
    top 300ms ease-in-out, left 300ms ease-in-out, transform 300ms ease-in-out,
    opacity 200ms ease-in-out;

  .resize-drag {
    display: none;
  }

  &:hover {
    .resize-drag {
      color: #777777;
      position: absolute;
      right: 2px;
      bottom: 0;
      display: block;
      cursor: nwse-resize;
      font-size: 16px;
    }
  }
`;

const WindowBar = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  background-color: ${(props) =>
    props.mouseDown ? "rgba(255,255,255,0.3)" : "rgba(255,255,255,0)"};

  &:hover {
    background-color: #f5f6fa;
  }
`;

const WindowIcon = styled.img`
  height: 20px;
  width: 20px;
`;

const WindowTitle = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  user-select: none;
`;

const WindowButtons = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;

  button {
    margin-left: 3px;
  }

  button:first-child {
    margin-left: 0;
  }
`;

const WindowButton = styled(Button)`
  height: 16px;
  width: 16px;
  border-radius: 24px;
`;

export const CloseButton = styled(WindowButton)`
  background-color: #ff6b6b;
`;

const MaximizeButton = styled(WindowButton)`
  background-color: #10ac84;
`;

const MinimizeButton = styled(WindowButton)`
  background-color: #feca57;
`;

const WindowContent = styled.div`
  padding: 10px;
  flex: 1;
`;

const WindowFooter = styled.div`
  flex: 0;
  padding: 10px;
  // background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ResizeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  transition: backdrop-filter 300ms ease-in-out;

  //border: solid 2px red;
`;

const ResizeBorder = styled.div`
  position: absolute;
  border: solid 1px #cccccc;
  z-index: 11;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const Window = ({
  wKey,
  isActive,
  state,
  stateUpdateCb,
  onClick,
  onCloseApp,
}) => {
  const [mouseDown, setMouseDown] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [initResizePosition, setInitResizePosition] = useState(null);
  const [initResizeState, setInitResizeState] = useState(null);
  const [resizeState, setResizeState] = useState(null);
  const [deltaDrag, setDeltaDrag] = useState({ x: 0, y: 0 });
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1);
  }, []);

  return (
    <>
      {/*{isResizing && (*/}
      <ResizeOverlay
        style={{
          pointerEvents: isResizing ? "unset" : "none",
          backdropFilter: isResizing ? " grayscale(70%)" : "none",
        }}
        onMouseMove={(e) => {
          if (!isResizing) return;
          // console.log(e.clientX, e.clientY);
          // console.log("[-------");
          // console.log(state.h, e.clientY, initResizePosition.y);
          setResizeState({
            y: initResizeState.h + e.clientY - initResizePosition.y,
            x: initResizeState.w + e.clientX - initResizePosition.x,
          });
        }}
        onMouseUp={() => {
          if (!isResizing) return;
          if (!resizeState) return;
          dispatch(
            resizeWindow({
              windowKey: wKey,
              y: resizeState.y,
              x: resizeState.x,
            })
          );

          setIsResizing(false);
          setInitResizePosition(null);
          setInitResizeState(null);
          setResizeState(null);
        }}
      />
      {/*)}*/}
      {isResizing && (
        <ResizeBorder
          style={{
            top: state.t + deltaDrag.y,
            left: state.l + deltaDrag.x,
            width: resizeState ? resizeState.x : state.w,
            height: resizeState ? resizeState.y : state.h,
          }}
        />
      )}
      <Draggable
        // axis="x"
        bounds="parent"
        handle=".handle"
        // defaultPosition={{ x: 0, y: 0 }}
        // position={null}
        // grid={[25, 25]}
        scale={1}
        onStop={(e, a) => {
          setDeltaDrag({ x: a.x, y: a.y });
          setIsDragging(false);
        }}
        onStart={() => setIsDragging(true)}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
      >
        <Container
          onClick={onClick}
          state={state}
          isActive={isActive}
          className={
            (state.maximized ? "window-maximized " : "") +
            (state.minimized ? "window-minimized " : "") +
            (state.closing ? "window-closing " : "")
          }
          isLoaded={loaded}
          style={mouseDown ? { transition: "none" } : {}}
        >
          <WindowBar
            className="handle"
            onDoubleClick={() => dispatch(maximizeWindow(wKey))}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => setMouseDown(false)}
            mouseDown={mouseDown}
          >
            <CollapsibleCol>
              <WindowButtons>
                {state.resizable && (
                  <MaximizeButton
                    onClick={() => dispatch(maximizeWindow(wKey))}
                  />
                )}
              </WindowButtons>
            </CollapsibleCol>
            <ExpandableCol>
              {/*<WindowIcon src={getAppIconById(state.appId)} />*/}
              <WindowTitle>{state.title}</WindowTitle>
            </ExpandableCol>
            <CollapsibleCol>
              <WindowButtons>
                <MinimizeButton
                  onClick={() => dispatch(minimizeWindow(wKey))}
                />
                <CloseButton onClick={() => onCloseApp()} />
              </WindowButtons>
            </CollapsibleCol>
          </WindowBar>
          <WindowContent className={"app-layout-wrapper"}>
            {state.layout
              ? getLayout(state.layout, "layout-" + wKey)
              : getAppContentById(state.appId)}
          </WindowContent>
          <WindowFooter>
            <Button
              className={"large-button"}
              onClick={() => {
                dispatch(fadeWindow(wKey));
                setTimeout(() => {
                  dispatch(closeWindow(wKey));
                }, 200);
              }}
            >
              close
            </Button>
          </WindowFooter>
          {!state.maximized && state.resizable && (
            <div
              className={"resize-drag"}
              onMouseDown={(e) => {
                setIsResizing(true);
                if (!initResizePosition) {
                  setInitResizePosition({ x: e.clientX, y: e.clientY });
                }
                if (!initResizeState) {
                  setInitResizeState({ h: state.h, w: state.w });
                }
              }}
            >
              &#x022F0;
            </div>
          )}
        </Container>
      </Draggable>
    </>
  );
};

export default Window;
