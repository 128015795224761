import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Window, { CloseButton } from "../window/Window";
import moment from "moment";
import { CollapsibleCol, ExpandableCol, Row } from "../layout";
import { closeWindow } from "../../../redux/features/windows/windows";
import { useDispatch } from "react-redux";
import { toggleSearch } from "../../../redux/features/desktop/desktop";
import appList from "../../../apps/apps";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  height: 48px;
`;

const AppIconContainer = styled.div`
  height: 36px;
  transform-origin: bottom;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-top: 6px;
`;

const AppIcon = styled.img`
  height: 36px;
  width: 36px;
  padding: 0 5px;

  transition: width 200ms ease-in-out, height 200ms ease-in-out;
  &:hover {
    height: 72px;
    width: 72px;
  }
`;

const Dock = ({ launchApp }) => {
  return (
    <Container>
      {appList.map((a) => (
        <AppIconContainer key={"dock-icon-" + a.id}>
          <div className="tooltip">
            <AppIcon
              src={a.icon}
              onClick={() => launchApp(a.id)}
              title={a.title}
            />
            <span className="tooltip-text tooltip-top">{a.title}</span>
          </div>
        </AppIconContainer>
      ))}
    </Container>
  );
};

export default Dock;
