import DesktopSettings from "./desktopSettings/desktopSettings";
import Clock from "./clock/app";
import Notepad from "./notepad/app";
import About from "./about/app";
import Browser from "./browser/app";

const appList = [
  {
    id: "calculator",
    title: "Calculator",
    height: require("./calculator/config.json").settings.height,
    width: require("./calculator/config.json").settings.width,
    layout: require("./calculator/layout.json"),
    icon: require("./calculator/icon.png").default,
  },
  {
    id: "clock",
    title: "Clock",
    height: require("./clock/config.json").settings.height,
    width: require("./clock/config.json").settings.width,
    windowComponent: <Clock />,
    icon: require("./clock/icon.png").default,
  },
  {
    id: "notepad",
    title: "Notepad",
    height: require("./notepad/config.json").settings.height,
    width: require("./notepad/config.json").settings.width,
    windowComponent: <Notepad />,
    icon: require("./notepad/icon.png").default,
  },
  {
    id: "browser",
    title: "Browser",
    height: require("./browser/config.json").settings.height,
    width: require("./browser/config.json").settings.width,
    resizable: require("./browser/config.json").settings.resizable,
    windowComponent: <Browser />,
    icon: require("./browser/icon.png").default,
  },
  {
    id: "desktopSettings",
    title: "Desktop Settings",
    height: 400,
    width: 600,
    windowComponent: <DesktopSettings />,
    icon: require("./desktopSettings/icon.png").default,
  },
  {
    id: "about",
    title: "About",
    height: require("./about/config.json").settings.height,
    width: require("./about/config.json").settings.width,
    resizable: require("./about/config.json").settings.resizable,
    windowComponent: <About />,
    icon: require("./about/icon.png").default,
  },
];

export const getAppById = (id) => {
  return appList.find((a) => a.id === id);
};

export const getAppIconById = (id) => {
  return appList.find((a) => a.id === id).icon;
};

export const getAppContentById = (id) => {
  return appList.find((a) => a.id === id).windowComponent;
};

export default appList;
