import React, { useCallback, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import "./Desktop.scss";
import Window from "../layout/window/Window";
import cursor from "../../assets/cursor.png";
import Taskbar from "../layout/taskbar/Taskbar";
import appList, { getAppById } from "../../apps/apps";
import { useSelector, useDispatch } from "react-redux";
import {
  addWindow,
  closeWindow,
  fadeWindow,
  resizeWindow,
  restoreWindow,
  setActiveWindow,
} from "../../redux/features/windows/windows";
import { hideSearch } from "../../redux/features/desktop/desktop";
import Dock from "../layout/dock/Dock";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  background-color: #10ac84;
  background-image: url(${require("../../assets/wallpapers/1.jpg").default});
  background-size: cover;
  cursor: url(${cursor}), auto;
  transition: background-image 0.5s ease;
`;

const WindowsWrapper = styled.div`
  position: fixed;
  top: 36px;
  left: 0;
  right: 0;
  bottom: 48px;
`;

const SearchContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 250px;
  width: 450px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  padding: 16px;
  transition: width 300ms ease-in-out, height 300ms ease-in-out,
    top 300ms ease-in-out, left 300ms ease-in-out, transform 300ms ease-in-out,
    opacity 200ms ease-in-out;
  overflow: hidden;
`;

const SearchList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  max-height: 70vh;
  overflow-y: auto;
  flex-flow: wrap;
  justify-content: center;
`;

const AppItem = styled.div`
  flex: 33%;
  width: 33%;
  max-width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 0;
  font-size: 14px;

  &:hover {
    background-color: #f5f6fa;
  }

  img {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
  }
`;

const SearchOverlay = styled.div`
  position: absolute;
  top: 36px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 98;
  transition: backdrop-filter 300ms ease-in-out;
`;

const Desktop = () => {
  const activeWindowKey = useSelector((state) => state.window.activeWindowKey);
  const windows = useSelector((state) => state.window.windows);
  const desktop = useSelector((state) => state.desktop);
  const dispatch = useDispatch();

  const updateWindowState = (id, s) => {
    // setWindows({ ...windows, [id]: s });
  };

  const keyPressCb = (event) => {
    if (event.ctrlKey && event.keyCode === 49) {
      // Ctrl+1
      event.preventDefault();
      dispatch(
        resizeWindow({
          windowKey: activeWindowKey,
          y: window.innerHeight - 36 - 48,
          x: window.innerWidth / 3,
          l: 0,
          t: 0,
        })
      );
    }
    if (event.ctrlKey && event.keyCode === 50) {
      // Ctrl+2
      event.preventDefault();
      dispatch(
        resizeWindow({
          windowKey: activeWindowKey,
          y: window.innerHeight - 36 - 48,
          x: window.innerWidth / 3,
          l: window.innerWidth / 3,
          t: 0,
        })
      );
    }
    if (event.ctrlKey && event.keyCode === 51) {
      // Ctrl+3
      event.preventDefault();
      dispatch(
        resizeWindow({
          windowKey: activeWindowKey,
          y: window.innerHeight - 36 - 48,
          x: window.innerWidth / 3,
          l: (window.innerWidth * 2) / 3,
          t: 0,
        })
      );
    }
    if (event.ctrlKey && event.keyCode === 52) {
      // Ctrl+4
      event.preventDefault();
      event.preventDefault();
      dispatch(
        resizeWindow({
          windowKey: activeWindowKey,
          y: window.innerHeight - 36 - 48,
          x: window.innerWidth / 2,
          l: 0,
          t: 0,
        })
      );
    }
    if (event.ctrlKey && event.keyCode === 53) {
      // Ctrl+5
      event.preventDefault();
      event.preventDefault();
      dispatch(
        resizeWindow({
          windowKey: activeWindowKey,
          y: window.innerHeight - 36 - 48,
          x: window.innerWidth / 2,
          l: window.innerWidth / 2,
          t: 0,
        })
      );
    }
  };

  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => keyPressCb(e, activeWindowKey),
      false
    );

    return () => {
      document.removeEventListener("keydown", keyPressCb, false);
    };
  }, []);

  const launchApp = (id) => {
    const appData = getAppById(id);
    const windowId = uuidv4();
    console.log(appData);
    dispatch(
      addWindow({
        windowKey: windowId,
        windowData: {
          appId: id,
          t: window.innerHeight / 2 - appData.height / 2,
          l: window.innerWidth / 2 - appData.width / 2,
          h: appData.height,
          w: appData.width,
          minimized: false,
          maximized: false,
          resizable: appData.resizable !== undefined ? appData.resizable : true,
          title: appData.title,
          // content: appData.windowComponent,
          layout: appData.layout,
        },
      })
    );
    dispatch(setActiveWindow(windowId));
  };

  useEffect(() => {
    launchApp("about");
  }, []);

  return (
    <>
      <Container>
        <Taskbar
          windows={windows}
          activeWindow={activeWindowKey}
          launchApp={launchApp}
          onTabClick={(key) => {
            dispatch(restoreWindow(key));
          }}
        />

        <Dock launchApp={launchApp} />

        <WindowsWrapper>
          {Object.keys(windows).map((wKey) => {
            return (
              <Window
                onClick={() => {
                  dispatch(setActiveWindow(wKey));
                }}
                onCloseApp={() => {
                  dispatch(fadeWindow(wKey));
                  setTimeout(() => {
                    dispatch(closeWindow(wKey));
                  }, 200);
                }}
                key={wKey}
                wKey={wKey}
                isActive={activeWindowKey === wKey}
                state={windows[wKey]}
                stateUpdateCb={(s) => updateWindowState(wKey, s)}
              />
            );
          })}
        </WindowsWrapper>

        <SearchOverlay
          style={{
            backdropFilter: desktop.showSearch
              ? "brightness(0.5) grayscale(100%)"
              : "none",
            pointerEvents: desktop.showSearch ? "unset" : "none",
          }}
          onClick={() => {
            dispatch(hideSearch());
          }}
        />
        <SearchContainer
          className={desktop.showSearch ? "" : "search-container-collapsed"}
        >
          Apps
          <SearchList>
            {appList.map((a) => {
              return (
                <AppItem
                  key={"search-app-item-" + a.id}
                  onClick={() => {
                    dispatch(hideSearch());
                    launchApp(a.id);
                  }}
                >
                  <img src={a.icon} />
                  {a.title}
                </AppItem>
              );
            })}
          </SearchList>
        </SearchContainer>
      </Container>
    </>
  );
};

export default Desktop;
