import React, { useEffect, useRef } from "react";
import "./Clock.scss";

let clockInterval = null;

const Clock = () => {
  const hEl = useRef();
  const mEl = useRef();
  const sEl = useRef();
  const dateEl = useRef();
  const dayEl = useRef();

  useEffect(() => {
    function clock() {
      var weekday = new Array(7),
        d = new Date(),
        h = d.getHours(),
        m = d.getMinutes(),
        s = d.getSeconds(),
        date = d.getDate(),
        month = d.getMonth() + 1,
        year = d.getFullYear(),
        hDeg = h * 30 + m * (360 / 720),
        mDeg = m * 6 + s * (360 / 3600),
        sDeg = s * 6;

      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      var day = weekday[d.getDay()];

      if (month < 9) {
        month = "0" + month;
      }

      if (
        !hEl.current ||
        !mEl.current ||
        !sEl.current ||
        !dateEl.current ||
        !dayEl.current
      ) {
        return;
      }
      hEl.current.style.transform = "rotate(" + hDeg + "deg)";
      mEl.current.style.transform = "rotate(" + mDeg + "deg)";
      sEl.current.style.transform = "rotate(" + sDeg + "deg)";
      dateEl.current.innerHTML = date + "/" + month + "/" + year;
      dayEl.current.innerHTML = day;
    }

    clockInterval = setInterval(() => clock(), 100);

    return () => {
      clearInterval(clockInterval);
    };
  }, []);

  return (
    <div className="clock">
      <div>
        <div className="info date" ref={dateEl} />
        <div className="info day" ref={dayEl} />
      </div>
      <div className="dot" />
      <div>
        <div className="hour-hand" ref={hEl} />
        <div className="minute-hand" ref={mEl} />
        <div className="second-hand" ref={sEl} />
      </div>
      <div>
        <span className="h3">3</span>
        <span className="h6">6</span>
        <span className="h9">9</span>
        <span className="h12">12</span>
      </div>
      <div className="diallines" />
    </div>
  );
};

export default Clock;
