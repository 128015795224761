import { createSlice } from "@reduxjs/toolkit";

export const windowsSlice = createSlice({
  name: "windows",
  initialState: {
    windows: {},
    activeWindowKey: null,
  },
  reducers: {
    addWindow: (state, action) => {
      state.windows[action.payload.windowKey] = action.payload.windowData;
    },
    setActiveWindow: (state, action) => {
      state.activeWindowKey = action.payload;
    },
    maximizeWindow: (state, action) => {
      state.windows[action.payload].maximized = !state.windows[action.payload]
        .maximized;
    },
    minimizeWindow: (state, action) => {
      state.windows[action.payload].minimized = true;
    },
    restoreWindow: (state, action) => {
      state.windows[action.payload].minimized = false;
      state.activeWindowKey = action.payload;
    },
    resizeWindow: (state, action) => {
      const winKey = action.payload.windowKey ?? state.activeWindowKey;
      if (!winKey) return;

      console.log(action.payload);
      state.windows[winKey].w = action.payload.x;
      state.windows[winKey].h = action.payload.y;
      if (action.payload.l !== undefined) {
        state.windows[winKey].l = action.payload.l;
      }
      if (action.payload.t !== undefined) {
        state.windows[winKey].t = action.payload.t;
      }
    },
    fadeWindow: (state, action) => {
      state.windows[action.payload].closing = true;
    },
    closeWindow: (state, action) => {
      delete state.windows[action.payload];
      state.activeWindowKey = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addWindow,
  setActiveWindow,
  maximizeWindow,
  minimizeWindow,
  restoreWindow,
  resizeWindow,
  fadeWindow,
  closeWindow,
} = windowsSlice.actions;

export default windowsSlice.reducer;
