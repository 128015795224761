import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: ${(props) => props.flex ?? 1};
`;

const Button = styled.button`
  border: none;
  margin: 2px;
  border-radius: 8px;
  padding: 10px 10px;
  min-height: 36px;

  &:hover {
    filter: brightness(1.15);
  }

  &:active {
    filter: brightness(1.3);
  }
`;

const Input = styled.input`
  margin: 2px;
  border-radius: 8px;
`;

const getLayout = (layout, prefix) => {
  const content = [];

  for (let i = 0; i < layout.length; i++) {
    const l = layout[i];

    switch (l.type) {
      case "row":
        content.push(
          <Row
            key={prefix + "-" + i}
            style={l.style ?? {}}
            className={l.factor}
          >
            {getLayout(l.content, prefix + "-" + i)}
          </Row>
        );
        break;
      case "col":
        content.push(
          <Col
            key={prefix + "-" + i}
            flex={l.flex ?? 1}
            style={l.style ?? {}}
            className={l.factor}
          >
            {getLayout(l.content)}
          </Col>
        );
        break;
      case "button":
        content.push(
          <Button
            key={prefix + "-" + i}
            style={l.style ?? {}}
            className={l.factor}
          >
            {l.text}
          </Button>
        );
        break;
      case "input":
        content.push(
          <Input
            key={prefix + "-" + i}
            style={l.style ?? {}}
            className={l.factor}
            defaultValue={l.defaultText ?? ""}
          />
        );
        break;
      case "banner":
        content.push(
          <div
            key={prefix + "-" + i}
            style={Object.assign({ width: "calc(100% + 40px)", textAlign: "center", padding: "5px", margin: "0 -20px 10px -20px"}, l.style ?? {})}
            className={l.factor}
          >{l.text}</div>
        );
        break;
      case "text":
        content.push(
          <div
            key={prefix + "-" + i}
            style={l.style ?? {}}
            className={l.factor}
          >{l.text}</div>
        );
        break;
    }
  }

  return content;
};

export default getLayout;
