import { createSlice } from "@reduxjs/toolkit";

export const desktopSlice = createSlice({
  name: "desktop",
  initialState: {
    showSearch: false,
  },
  reducers: {
    showSearch: (state) => {
      state.showSearch = true;
    },
    hideSearch: (state) => {
      state.showSearch = false;
    },
    toggleSearch: (state) => {
      state.showSearch = !state.showSearch;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSearch, hideSearch, toggleSearch } = desktopSlice.actions;

export default desktopSlice.reducer;
