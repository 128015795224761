import React, { useEffect, useRef } from "react";
import "./Notepad.scss";
import styled from "styled-components";

const NotepadEditor = styled.textarea`
  height: 100%;
  width: 100%;
  flex: 1;
  outline: 0;
  padding: 8px;
`;

const Notepad = () => {
  return (
    <div style={{ height: "100%", display: "flex", marginBottom: "4px" }}>
      <NotepadEditor />
    </div>
  );
};

export default Notepad;
