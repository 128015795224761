import React, { useEffect, useRef } from "react";
import "./About.scss";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const AboutTitle = styled.div`
  font-size: 22px;
  margin-bottom: 10px;
`;

const AboutContent = styled.div`
  font-size: 14px;
  margin-bottom: 20px;

  a {
    color: dodgerblue;
  }
`;

const About = () => {
  return (
    <Wrapper>
      <AboutTitle>What's this?</AboutTitle>
      <AboutContent>
        The purpose of this is to demonstrate that web doesn't have to be boring.
      </AboutContent>
      <AboutContent>
        You can move, maximize, minimize the windows. You can even resize them
        by dragging the bottom-right corner of a window.
      </AboutContent>
      <AboutContent>
        Hit the top-right search button to open an app. Go nuts.
      </AboutContent>
      <AboutContent>
        <a
          href={"https://www.linkedin.com/in/sorin-banica-22a95351/"}
          target="_blank"
        >
          view my LinkedIn profile
        </a>
      </AboutContent>
    </Wrapper>
  );
};

export default About;
