import React from "react";

const DesktopSettings = () => {
    return <>
        Desktop Settings
        <p>nothing here yet</p>
    </>;
}

export default DesktopSettings;
