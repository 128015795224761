import logo from "./logo.svg";
import "./App.css";
import "./components/layout/layout.scss";
import { Provider } from "react-redux";
import store from "./redux/store";

import Desktop from "./components/desktop/Desktop";

function App() {
  return (
    <Provider store={store}>
      <Desktop />
    </Provider>
  );
}

export default App;
